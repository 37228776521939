<template>
  <div class="search-filter">
    <a-form-model
      class="search-form"
      ref="form"
      :model="form"
      :label-col="{flex: '0 0 100px'}"
      :wrapper-col="{flex: '0 0 calc(100% - 100px)'}"
    >
      <a-row :gutter="16">
        <a-col :span="colSpan">
          <a-form-model-item label="主体(品牌)" prop="principalId">
            <a-select
              v-model="form.principalId"
              :default-active-first-option="false"
              option-filter-prop="children"
              show-search
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in principalList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="经销商信息" prop="dealer">
            <a-input
              v-model="form.dealer"
              placeholder="请输入经销商名称/编号"
              allow-clear
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="开通时间" prop="time">
            <a-range-picker
              v-model="form.time"
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ format: 'HH:mm:ss' }"
              style="width: 100%;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="帐号信息" prop="userName">
            <a-input
              v-model="form.userName"
              allow-clear
              placeholder="请输入用户帐号"
            />
          </a-form-model-item>  
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="用户微信号" prop="weChatCode">
            <a-input
              v-model="form.weChatCode"
              allow-clear
              placeholder="请输入用户微信号"
            />
          </a-form-model-item>  
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="企微好友" prop="weChatStatus">
            <a-select
              v-model="form.weChatStatus"
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in wechatStatusList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>  
        </a-col>
        <a-col :span="colSpan2">
          <a-form-model-item :wrapper-col="{ flex: '1 0 auto' }">
            <div class="btn-wrap">
              <a-space>
                <a-button @click="handleReset">
                  重置
                </a-button>
                <a-button type="primary" :loading="searchLoading" @click="handleSearch">
                  查询
                </a-button>
              </a-space>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/dataTableMixin'

export default {
  name: 'SearchFilter',
  mixins: [resizeMixin],
  props: {
    form: {
      type: Object,
      default() {
        return {}
      }
    },
    principalList: {
      type: Array,
      default() {
        return []
      }  
    },
    wechatStatusList: {
      type: Array,
      default() {
        return []
      }
    },
    searchLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    this.$_resizeHandler = () => {
      this.setColSpan()
    };

    return {
      colSpan: 12,
      colSpan2: 24
    }
  },
  created() {
    this.setColSpan()
  },
  methods: {
    setColSpan() {
      const width = document.documentElement.offsetWidth

      if (width < 1200) {
        this.colSpan = 12
        this.colSpan2 = 24
      } else if (width >= 1200 && width < 1600) {
        this.colSpan = 8
        this.colSpan2 = 24
      } else if (width >= 1600) {
        this.colSpan = 6
        this.colSpan2 = 12
      }
    },
    //
    handleSearch () {
      this.$emit('search', { ...this.form })
    },
    //
    handleReset () {
      this.$refs.form.resetFields()
      this.$emit('search', { ...this.form })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-filter {
  padding: 24px 24px 16px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .ant-form-item {
    display: flex;
    margin-bottom: 8px;
  }

  .btn-wrap {
    text-align: right;
  }
}
</style>

<template>
  <div class="data-table" ref="dataTable">
    <a-table
      class="a-tables-scroll"
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => index"
      :pagination="false"
      :scroll="scroll"
    >
      <div slot="weChatStatus" slot-scope="text, record">
        <span>{{ record.isAddFriend | wechatStatusFilter }}</span>
      </div>
      <div slot="storeStatus" slot-scope="text, record">
        <span>{{ record.storeStatus | storeStatusFilter }}</span>
      </div>
      <div slot="authorStatus" slot-scope="text, record">
        <span>{{ record.authorStatus | authorStatusFilter }}</span>
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="handleActionClick('detail', record)">
          查看详情
        </a>
      </div>
    </a-table>
    <base-pagination
      ref="basePagination"
      :current-page="pagination.current"
      :page-size="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import dataTableMixin from "@/mixins/dataTableMixin";
import { wechatStatusFilter, authorStatusFilter, storeStatusFilter } from "@/utils/fhlUserConst";

const columns = [
  {
    title: "主体(品牌)",
    dataIndex: "principal",
    key: "principal",
    width: 150
  },
  {
    title: "经销商名称",
    dataIndex: "dealerName",
    key: "dealerName",
    width: 240
  },
  {
    title: "帐号信息",
    dataIndex: "username",
    key: "username",
    width: 120
  },
  {
    title: "使用人",
    dataIndex: "name",
    key: "name",
    width: 120
  },
  {
    title: "联系电话",
    dataIndex: "mobile",
    key: "mobile",
    width: 130
  },
  {
    title: "关联媒体帐号",
    dataIndex: "mediaCount",
    key: "mediaCount",
    width: 130
  },
  {
    title: "企微好友",
    key: "weChatStatus",
    width: 120,
    scopedSlots: {customRender: "weChatStatus"}
  },
  {
    title: "店端状态",
    key: "storeStatus",
    width: 120,
    scopedSlots: {customRender: "storeStatus"}
  },
  {
    title: "主播状态",
    key: "authorStatus",
    width: 200,
    scopedSlots: {customRender: "authorStatus"}
  },
  {
    title: "开通时间",
    dataIndex: "ctime",
    key: "ctime",
    width: 170
  },
  {
    title: "操作",
    key: "action",
    width: 100,
    fixed: "right",
    scopedSlots: {customRender: "action"}
  }
];

export default {
  name: "DataTable",
  mixins: [dataTableMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          current: 1,
          pageSize: 10,
          total: 0
        };
      }
    }
  },
  data() {
    return {
      columns,
      scroll: {}
    };
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.setTableScroll()
      })
    }
  },
  methods: {
    //
    handleActionClick(type, record) {
      this.$emit("action-click", type, {...record});
    },
    //
    handlePaginationChange(current, pageSize) {
      this.$emit("pagination-change", current, pageSize);
    },
    setTableScroll() {
      const winHeight = document.documentElement.offsetHeight || document.body.offsetHeight
      const width = this.$refs.dataTable.offsetWidth
      const height = this.$refs.dataTable.offsetHeight
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0)
      const scroll = {}

      if (width - 48 < columnsWidth) {
        scroll.x = columnsWidth
      }

      if (height + 142 > winHeight) {
        const actionBarHeight = this.$refs.actionBar ? this.$refs.actionBar.$el.offsetHeight : 0
        const tableHeadHeight = document.querySelector('.ant-table-thead').offsetHeight
        const otherHeight  = actionBarHeight + tableHeadHeight + 190
        
        scroll.y = `calc(100vh - ${otherHeight}px)`
      }

      this.scroll = scroll
    },
  },
  filters: {
    wechatStatusFilter,
    storeStatusFilter,
    authorStatusFilter
  },
  mounted() {
    this.setTableScroll()
    this.$_resizeHandler = () => {
      this.setTableScroll()
    }
  }
}
</script>

<style lang="less" scoped>
.data-table {
  padding: 24px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}

.a-tables-scroll /deep/ .ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }

  ul::-webkit-scrollbar { // 整个滚动条.
    width: 6px;
  }

  &::-webkit-scrollbar-thumb { // 滚动条上的滚动滑块
    border-radius: 8px;
    background-color: #7f7f7f;
  }

  &::-webkit-scrollbar-corner { // 当同时有垂直滚动条和水平滚动条时交汇的部分
    background_color: #242832;
  }

  &::-webkit-scrollbar-track { // 滚动条轨道
    border-radius: 0;
    background_color: #242832;
  }

}
</style>

<template>
  <section class="page">
    <SearchFilter
      class="mb-16"
      :form="searchForm"
      :principal-list="principalList"
      :wechat-status-list="wechatStatusList"
      :search-loading="loading"
      @search="handleSearch"
    />

    <DataTable
      :loading="loading"
      :data="userList"
      :pagination="pagination"
      @action-click="handleActionClick"
      @pagination-change="handlePaginationChange"
    />
  </section>
</template>

<script>
import SearchFilter from "./components/SearchFilter";
import DataTable from "./components/DataTable";
import { wechatStatusList } from '@/utils/fhlUserConst'
import fhlUserApi from "@/api/fhlUser";

export default {
  name: "userList",
  components: {
    SearchFilter,
    DataTable
  },
  data() {
    return {
      searchForm: {
        dealer: "",
        time: [],
        principalId: "",
        userName: '',
        weChatCode: '',
        weChatStatus: ''
      },
      principalList: [],
      wechatStatusList: [
        { label: '不限', value: '' },
        ...wechatStatusList
      ],
      loading: false,
      userList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    };
  },
  created() {
    this.fetchFhlUserList();
    this.fetchPrincipalList();
  },
  methods: {
    // 处理查询
    handleSearch() {
      this.pagination.current = 1;
      this.fetchFhlUserList();
    },
    // 处理分页改变
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.fetchFhlUserList();
    },
    // 获取数据
    async fetchFhlUserList() {
      this.loading = true;
      try {
        const {time, ...restSearchForm} = this.searchForm;
        const {current, pageSize} = this.pagination;
        const params = {
          ...restSearchForm,
          startTime: time.length > 0 ? time[0] : "",
          endTime: time.length > 0 ? time[1] : "",
          page: current,
          size: pageSize
        };
        const {code, data, message} = await fhlUserApi.fetchFhlUserList(params);

        if(code === 200) {
          this.userList = data.list || [];
          this.pagination.total = data.total || 0;
          this.loading = false;
        } else {
          this.$message.error(message);
          this.loading = false;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    //
    async fetchPrincipalList() {
      try {
        const res = await this.$api.core.TrainingList.getPrincipals();

        if(Array.isArray(res)) {
          this.principalList = [
            {label: "全部", value: ""},
            ...res.map(item => {
              return {label: item.principal_name, value: item.id};
            })
          ];
        } else {
          this.principalList = [];
          console.log("获取主体失败");
        }
      } catch(e) {
        console.log(e);
      }
    },
    // 处理操作点击
    handleActionClick(type, record) {
      if(type === "detail") {
        this.$router.push({
          name: "fhlUser:detail",
          query: {
            userId: record.userId
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mb-16 {
  margin-bottom: 16px;
}

.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}

.page-container {
  padding: 0 !important;

  .data-table {
    margin: 10px 0 0;
  }
}
</style>
